.contact{
    padding: 0 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
}

@media (max-width: 992px){
    .contact{
        flex-direction: column;
    }
}

.left-c{
    flex: 1;
    position: relative;
}

@media (max-width: 992px){
    .left-c{
        text-align: center;
    }
}

.left-c div{
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
}

@media (max-width: 992px){
    .left-c div{
        font-size: 2rem;
    }
}

.left-c div:nth-child(1){
    margin-bottom: 1rem;
}

.left-c div span:not(.stroke-text){
    color: white;
}

.left-c::before{
    content: "";
    position: absolute;
    top: -1rem;
    left: 0;
    width: 10rem;
    height: 5px;
    background-color: var(--orange);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

@media (max-width: 992px){
    .left-c::before{
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

.right-c{
    flex: 1;
}

.right-c form{
    position: relative;
    width: 346px;
}

form input{
    padding: 1.5rem 2rem;
    padding-right: 8rem;
    border: none;
    outline: none;
    caret-color: white;
    background-color: var(--gray);
}

form input::placeholder{
    color: white;
}

form button.btn{
    background-color: var(--orange);
    color: white;
    position: absolute;
    top: 50%;
    right: 2rem;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}