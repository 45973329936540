.reasons {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 0 2rem;
}

@media (max-width: 992px){
    .reasons{
        flex-direction: column;
    }
}


.left-r {
    flex: 1;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* grid-auto-rows: 1fr; */
    grid-template-rows: auto auto;
}

@media (max-width: 768px){
    .left-r{
        width: 320px;
        margin: auto;
}
}

.left-r img {
    object-fit: cover;
}

.left-r img:nth-child(1) {
    width: 12rem;
    height: 28rem;
    grid-row: 1/3;
}

.left-r img:nth-child(2) {
    width: 25rem;
    height: 16rem;
    grid-column: 2/4;
}

.left-r img:nth-child(3) {
    width: 14rem;
    height: 11rem;
    grid-column: 2/3;
}

.left-r img:nth-child(4) {
    width: 10rem;
    height: 11rem;
    grid-column: 3/4;
}

@media(max-width: 768px){
    .left-r img:nth-child(1) {
        width: 6rem;
        height: 14rem;
        grid-row: 1/3;
    }
    
    .left-r img:nth-child(2) {
        width: 13rem;
        height: 8rem;
        grid-column: 2/3;
    }
    
    .left-r img:nth-child(3) {
        width: 6rem;
        height: 5.5rem;
        grid-column: 2/3;
    }
    
    .left-r img:nth-child(4) {
        width: 6rem;
        height: 5.5rem;
        grid-column: 3/4;
        margin-left: -7rem;
    }
}

.right-r{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    font-weight: bold;
}

.right-r > span:nth-child(1){
    color: var(--orange);
}

.right-r > div:nth-of-type(1) > span{
    font-size: 3rem;
}

.right-r > div:nth-of-type(1) > span:last-of-type{
    color: white;
}

.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r div{
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
}

.details-r div img{
    width: 2rem;
}

.right-r span:nth-of-type(2){
    color: var(--gray);
    font-weight: normal;
}

.partners{
    display: flex;
    gap: 1rem;
}

.partners img{
    width: 2.5rem;
}

@media(max-width: 768px){
    .right-r{
        text-align: center;
    }

    .right-r .details-r div,
    .right-r .partners{
        justify-content: center;
    }
}