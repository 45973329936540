.footer{
    padding: 6rem 2rem;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.media{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.media img{
    width: 2rem;
}

.footer .logo{
    margin: auto;
}

.footer .logo img{
    width: 10rem;
    height: 3rem;
}

.footer-blur{
    width: 32rem;
    height: 23rem;
    bottom: -10rem;
}

.footer .footer-blur:nth-child(1){
    left: 50%;
    background-color: rgba(248, 53, 53, 0.582);
}

.footer .footer-blur:nth-child(2){
    right: 50%;
}