.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

@media (max-width: 992px){
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: 2rem;
    }
}

.programs-categories{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.programs-categories .category{
    width: calc((100% - 3rem) / 4);
    padding: 2rem;
    color: white;
    background: var(--caloryCard);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s;
}

.programs-categories .category:hover{
    background: var(--planCard);
}

@media (max-width: 992px){
    .programs-categories .category{
        width: calc((100% - 1rem) / 2);
    }
}

@media (max-width: 768px){
    .programs-categories .category{
        width: 100%;
    }
}

.programs-categories .category svg{
    width: 2rem;
    height: 2rem;
    fill: white;
}

.programs-categories .category span:nth-of-type(1){
    font-weight: bold;
}

.programs-categories .category span:nth-of-type(2){
    font-size: .9rem;
    line-height: 25px;
}

.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.join-now img{
    width: 1rem;
}

