.hero {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 992px){
    .hero{
        height: 1500px;
        flex-direction: column;
    }
}

@media (max-width: 768px){
    .hero{
        text-align: center;
    }
}


.left-h {
    flex: 3;
    padding: 2rem;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.right-h {
    background-color: var(--orange);
    position: relative;
    flex: 1;
}

@media (max-width: 992px){
    .left-h{
        flex: 1;
    }
    .right-h {
        background-color: transparent;
    }
}

.the-best-ad {
    margin-top: 3rem;
    width: 330px;
    padding: 20px 12px;
    background-color: var(--appColor);
    color: white;
    text-transform: uppercase;
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    -ms-border-radius: 3rem;
    -o-border-radius: 3rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.the-best-ad div {
    position: absolute;
    left: 8px;
    width: 5.4rem;
    height: 80%;
    background-color: var(--orange);
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    -ms-border-radius: 3rem;
    -o-border-radius: 3rem;
    border-radius: 3rem;
    z-index: 1;
}

@media (max-width: 768px){
    .the-best-ad{
        margin-left: auto;
        margin-right: auto;
        /* width: 350px; */
    }
}

.the-best-ad span {
    z-index: 2;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    text-overflow: inherit;
}

.hero-text .stroke-text{
    margin-right: 1rem;
}

.hero-text div:nth-child(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

@media (max-width: 768px){
    .hero-text{
        font-size: 3rem;
    }

    .hero-text div:nth-child(3){
        margin: auto;
    }
}

.figures{
    display: flex;
    align-items: center;
    gap: 2rem;
}

@media (max-width: 768px){
    .figures{
        flex-direction: column;
    }
}

.figures div{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
}

.figures div span:last-of-type{
    color: var(--gray);
    font-size: 1rem;
}

.hero-buttons{
    display: flex;
    gap: 1rem;
}

@media (max-width: 768px){
    .hero-buttons{
        margin: auto;
    }
}

.hero-buttons .btn{
    width: 8rem;
    font-weight: normal;
    border-color: var(--orange);
    color: white;
}

.hero-buttons .btn:nth-child(1){
    /* margin-right: 1rem; */
    background-color: var(--orange);
}

.hero-buttons .btn:nth-child(1):hover{
    background-color: transparent;
}

.hero-buttons .btn:nth-child(2){
    background-color: transparent;
}

.hero-buttons .btn:nth-child(2):hover{
    background-color: white;
    border-color: white;
    color: black;
}

.right-h .btn{
    position: absolute;
    top: 2rem;
    right: 3rem;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 6rem;
    right: 4rem;
    padding: 1rem;
    width: 127px;
    background-color: #6565656e;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
}

.heart-rate img{
    width: 2rem;
}

.heart-rate span:nth-of-type(1){
    color: var(--gray);
}

.heart-rate span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}

.hero-image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
}

@media (max-width: 768px){
    .hero-image{
        top: 11rem;
        right: 2rem;
        width: 20rem;
    }
}

.hero-image-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
}

.calories{
    position: absolute;
    top: 32rem;
    right: 32rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    background-color: #6565656e;
    color: var(--gray);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
}

@media (max-width: 768px){
    .calories{
        right: 4rem;
    }
}

.calories img{
    width: 4rem;
}

.calories div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories div span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}

.hero-blur{
    width: 22rem;
    height: 22rem;
    left: 0;
}

@media (max-width: 350px){
    .hero-blur{
        width: 0;
    }
}